import React from 'react'
import logo from '../../assets/logo/bjp-logo.png'


function ContactSection() {
    return (
        <>
            {/* <div className='px-[5%] xl:px-[8%] py-20'> */}
            <div className='grid grid-cols-12 gap-5'>
                <div className='col-span-12 lg:col-span-6 flex flex-col justify-center items-center py-10 lg:py-20 bg-background1'>
                    <img className='w-20 h-20 mb-2' src={logo} alt="bjp logo" />
                    <p className='uppercase mb-8 lg:text-2xl '>bharathiya janatha party (BJP)</p>
                    <p className='text-base'>Start Changing</p>
                    <p className='text-2xl capitalize mt-5'>Support our cause</p>
                </div>
                <div className='col-span-12 lg:col-span-6 bg-siteGreen py-10 lg:px-32 text-white'>
                    <p className='text-center uppercase pb-5'>subscribe to our newsletter</p>
                    <p className='text-center text-2xl'>Get the latest update from the last compaign trail</p>
                    <div className='bg-siteOrange p-5 mt-10'>
                        <form action="">
                            <div className='flex justify-between'>
                                <input className='p-2 w-full' type="email" placeholder='Enter Your Email' />
                                <button className='bg-siteGreen p-2'>subscribe</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    )
}

export default ContactSection

import React from 'react'
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import aboutme from '../../assets/home/aboutme.jpg'

function AboutSection() {
    return (
        <>
            <div className='px-[5%] xl:px-[8%] py-10 bg-background1'>
                <div className='grid grid-cols-12 gap-5'>
                    <div className='col-span-12 xl:col-span-6 lg:pt-10 lg:ps-16 lg:pe-28 bg-white'>
                        <h2 className='text-orange-400 text-2xl xl:text-3xl mb-5 font-bold '>C K Ramamurthy</h2>
                        <p className='text-gray-800'>​C K Ramamurthy, presently a Member of Karnataka State BJP Executive Committee,
                            he's been deeply involved in politics for over 3 decades, held many organizational positions in BHARATIYA
                            JANATA PARTY (BJP), has been recognised as Leader, nurtured various skills to help people and community
                            through various initiatives and more.</p>
                        <div className='flex justify-between mt-10'>
                            <button className='bg-siteGreen capitalize text-white p-2 px-4'>about me</button>
                            <p className='flex gap-3 mt-2'>
                                <FaFacebookF size={20} />
                                <FaTwitter size={20} />
                                <FaInstagram size={20} />
                                <FaYoutube size={20} />
                            </p>
                        </div>
                    </div>
                    <div className='col-span-12 xl:col-span-6'>
                        <img src={aboutme} alt="about" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutSection

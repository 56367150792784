import React from 'react';
import HomeIndex from '../pages/HomeIndex';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import NavBar from '../components/navbar/NavBar';
import FooterIndex from '../components/footer/FooterIndex';
import ScrollTop from '../components/scrollTop/ScrollTop';

function RoutesIndex() {
    return (
        <Router>
            <ScrollTop />
            <NavBar />
            <Routes>
                <Route path="/" element={<HomeIndex />} />
            </Routes>
            <FooterIndex />
        </Router>
    );
}

export default RoutesIndex;

import React from 'react';
import develop1 from '../../assets/home/develop1.jpg';
import develop2 from '../../assets/home/develop2.jpg';
import develop3 from '../../assets/home/develop3.jpg';
import develop4 from '../../assets/home/develop4.jpg';

function DevelopmentSection() {
    return (
        <>
            <div className='px-[5%] xl:px-[8%] py-10'>
                <h2 className='text-4xl text-siteGreen text-center mb-10 font-bold'>Developments</h2>
                <div className='grid grid-cols-12 gap-5 lg:px-24'>
                    <div className='relative col-span-12 lg:col-span-6'>
                        <div
                            className='w-full h-[400px] bg-cover bg-center'
                            style={{ backgroundImage: `url(${develop1})` }}
                        />
                        <div className='absolute inset-0 bg-black bg-opacity-50 flex items-end justify-start'>
                            <p className='text-white text-xl font-medium px-7 pb-5'>Bengaluru South's 100th JanAushadhi Kendra, Namo Free Dialsis Centre launched on 7/March/2023</p>
                        </div>
                    </div>
                    <div className='relative col-span-12 lg:col-span-6'>
                        <div
                            className='w-full h-[400px] bg-cover bg-center'
                            style={{ backgroundImage: `url(${develop2})` }}
                        />
                        <div className='absolute inset-0 bg-black bg-opacity-50 flex items-end justify-start'>
                            <p className='text-white text-xl font-medium px-7 pb-5'>ಜಯನಗ ಶಾಪಿಂಗ್ ಕಾಂಪ್ಲೆಕ್ಸ್ ನ ಫುಟ್ ಪಾತ್ ನಸುತ್ತ ಅನಧಿಕೃತವಾಗಿ ಅಂಗಡಿಯ ತರವು</p>
                        </div>
                    </div>
                    <div className='relative col-span-12 lg:col-span-6'>
                        <div
                            className='w-full h-[400px] bg-cover bg-center'
                            style={{ backgroundImage: `url(${develop3})` }}
                        />
                        <div className='absolute inset-0 bg-black bg-opacity-50 flex items-end justify-start'>
                            <p className='text-white text-xl font-medium px-7 pb-5'>Few of BBMP Grants</p>
                        </div>
                    </div>
                    <div className='relative col-span-12 lg:col-span-6'>
                        <div
                            className='w-full h-[400px] bg-cover bg-center'
                            style={{ backgroundImage: `url(${develop4})` }}
                        />
                        <div className='absolute inset-0 bg-black bg-opacity-50 flex items-end justify-start'>
                            <p className='text-white text-xl font-medium px-7 pb-5'>E-Library in Jayanagar</p>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center mt-5'>
                    <button className='bg-siteOrange p-2 px-7 text-white capitalize'>read more</button>
                </div>
            </div>
        </>
    )
}

export default DevelopmentSection;

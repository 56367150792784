import React from 'react'
import gallery1 from '../../assets/gallery/slide1 (3)_edited.jpg'
import gallery2 from '../../assets/gallery/slide1 (7)_edited_edited.jpg'
import gallery3 from '../../assets/gallery/slide1 (8)_edited.jpg'
import gallery4 from '../../assets/gallery/1 (3).jpg'
import gallery5 from '../../assets/gallery/1 (5).jpg'
import gallery6 from '../../assets/gallery/1 (1).jpg'


function GallerySection() {
    return (
        <>
            <div className=''>
                <div className='grid grid-cols-12 gap-5'>
                    <div className='col-span-12 md:col-span-4'>
                        <img className='w-full h-auto md:h-[180px] lg:h-[230px] xl:h-[270px]' src={gallery1} alt="" />
                    </div>
                    <div className='col-span-12 md:col-span-4'>
                        <img className='w-full h-auto md:h-[180px] lg:h-[230px] xl:h-[270px]' src={gallery2} alt="" />
                    </div>
                    <div className='col-span-12 md:col-span-4'>
                        <img className='w-full h-auto md:h-[180px] lg:h-[230px] xl:h-[270px]' src={gallery3} alt="" />
                    </div>
                    <div className='col-span-12 md:col-span-3'>
                        <img className='w-full h-auto md:h-[180px] lg:h-[230px] xl:h-[270px]' src={gallery4} alt="" />
                    </div>
                    <div className='col-span-12 md:col-span-4'>
                        <img className='w-full h-auto md:h-[180px] lg:h-[230px] xl:h-[270px]' src={gallery5} alt="" />
                    </div>
                    <div className='col-span-12 md:col-span-5'>
                        <img className='w-full h-auto md:h-[180px] lg:h-[230px] xl:h-[270px]' src={gallery6} alt="" />
                    </div>
                </div>
                {/* <div className='grid grid-cols-12 gap-5'>
                    <div className='col-span-12 md:col-span-4'>
                        <img className='w-full h-auto md:h-[180px] lg:h-[230px] xl:h-[270px]' src={gallery4} alt="" />
                    </div>
                    <div className='col-span-12 md:col-span-4'>
                        <img className='w-full h-auto md:h-[180px] lg:h-[230px] xl:h-[270px]' src={gallery5} alt="" />
                    </div>
                    <div className='col-span-12 md:col-span-4'>
                        <img className='w-full h-auto md:h-[180px] lg:h-[230px] xl:h-[270px]' src={gallery6} alt="" />
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default GallerySection

import React from 'react'
import heroBanner from '../../assets/home/hero-banner.jpg'
import logo from '../../assets/logo/bjp-logo.png'
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

function HeroSection() {
    return (
        <>
            <div className='bg-siteblue h-screen'>
                <div className='grid grid-cols-12'>
                    <div className='col-span-12 lg:col-span-6'>
                        <img className='lg:h-screen object-cover' src={heroBanner} alt="hero banner" />
                    </div>
                    <div className='col-span-12 lg:col-span-6 text-white pt-28 px-[5%] lg:px-0 lg:pe-[8%]'>
                        <div className='flex flex-col justify-center items-center'>
                            <img className='w-20 h-20 mb-2' src={logo} alt="bjp logo" />
                            <p className='uppercase mb-8 text-xl lg:text-2xl'>bharathiya janatha party (BJP)</p>
                            <p className='uppercase underline tracking-widest mb-8 text-base lg:text-xl'>a brighter future</p>
                            <p className='capitalize text-2xl lg:text-6xl text-center mb-10 lg:leading-[60px]'>let's make a <br /> difference</p>
                            <p className='flex gap-3 mt-2'>
                                <FaFacebookF size={25} />
                                <FaTwitter size={25} />
                                <FaInstagram size={25} />
                                <FaYoutube size={25} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroSection

import React from 'react'
import HeroSection from './homeSections.jsx/HeroSection'
import AboutSection from './homeSections.jsx/AboutSection'
import DevelopmentSection from './homeSections.jsx/DevelopmentSection'
import MissionSection from './homeSections.jsx/MissionSection'
import ContactSection from './homeSections.jsx/ContactSection'
import GallerySection from './homeSections.jsx/GallerySection'

function HomeIndex() {
    return (
        <>
            <HeroSection />
            <AboutSection />
            <DevelopmentSection />
            <MissionSection />
            <GallerySection />
            <ContactSection />
        </>
    )
}

export default HomeIndex

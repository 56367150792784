import React from 'react'
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

function MissionSection() {
    return (
        <>
            <div className='bg-siteGreen text-white'>
                <div className='px-[5%] xl:px-[8%] py-20'>
                    <div className='grid grid-cols-12 gap-5'>
                        <div className='col-span-12 lg:col-span-6 flex items-center'>
                            <h2 className='uppercase lg:ps-10 text-2xl xl:text-4xl font-semibold'>mission</h2>
                        </div>
                        <div className='col-span-12 lg:col-span-6'>
                            <p>Success requires qualities of foresight, determination, meticulour planning, solutions to problems, quality
                                and timely competion of task taken up this ensuiring trust redefining lif standards.</p>
                            <div className='h-[1px] w-[70px] border-white border-[1px] mt-5'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-siteOrange text-white'>
                <div className='px-[5%] xl:px-[8%] py-20'>
                    <div className='grid grid-cols-12 gap-5'>
                        <div className='col-span-12 lg:col-span-6 flex items-center'>
                            <h2 className='uppercase lg:ps-10 text-2xl xl:text-4xl font-semibold'>objective</h2>
                        </div>
                        <div className='col-span-12 lg:col-span-6'>
                            <p>To work for an organization which will provide me opportunities to contribute
                                significantly to the organization where my interests, aptitude and proficiency will allow me the opportunity
                                for growth and development of the orgaization as well as learn from working in a challenging environment,
                                concurrently nurturing my skills and anility to obtain a challenging position that reauires strong
                                analytical
                                and functional skills and result oriented attitude. </p>
                            <div className='h-[1px] w-[70px] border-white border-[1px] mt-5'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-primary text-white'>
                <div className='px-[5%] xl:px-[8%] py-5'>
                    <p className='uppercase text-center'>follow c k ramamurthy on social media for updates</p>
                    <div className='flex justify-center'>
                        <p className='flex gap-3 mt-2'>
                            <FaFacebookF size={15} />
                            <FaTwitter size={15} />
                            <FaInstagram size={15} />
                            <FaYoutube size={15} />
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MissionSection

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function ScrollTop() {
    const location = useLocation();

    // Reset scroll position
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return null;
}

export default ScrollTop;
import React from 'react'
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

function FooterIndex() {
    return (
        <>
            <div className='bg-secondary'>
                <div className='px-[5%] xl:px-[8%] py-10'>
                    <div className='flex flex-col lg:flex-row  justify-between gap-y-10  text-white  pb-32'>
                        <div className='flex flex-col lg:items-center capitalize leading-9 order-2 lg:order-1'>
                            <p>home</p>
                            <p>about me</p>
                            <p>news</p>
                            <p>contact</p>
                        </div>
                        <div className='flex flex-col lg:items-center leading-8 order-1 lg:order-2'>
                            <p className='text-2xl'>C K Ramamoorthy</p>
                            <p className='uppercase tracking-widest text-sm'>- for jayanagar -</p>
                            <p className='flex gap-3 mt-2'>
                                <FaFacebookF />
                                <FaTwitter />
                                <FaInstagram />
                                <FaYoutube />
                            </p>
                        </div>
                        <div className='flex flex-col lg:items-center leading-9 order-3 lg:order-3'>
                            <p># 712, 10th A Main 4th Block</p>
                            <p>jayanagar, bengaluru - 560011</p>
                            <p>Email: mail@ckramamurthy.com</p>
                            <p>phone: +91 98450 00683</p>
                        </div>
                    </div>
                    <div className='opacity-50 text-sm text-white'>
                        <p>@ 2024 by C K Ramamurthy</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterIndex

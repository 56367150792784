import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { FaXmark } from "react-icons/fa6";
import { TfiAlignJustify } from "react-icons/tfi";
import logo from '../../assets/logo/bjp-logo.png'

function NavBar() {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className='bg-primary'>
                <div className='px-[5%] xl:px-[8%] py-3 hidden lg:block'>
                    <div className='flex justify-between items-center text-white capitalize'>
                        <div>
                            <p className='text-xl'>C K Ramamurthy</p>
                            <p className=''>MLA JAYANAGAR</p>
                        </div>
                        <div className='flex justify-start gap-8'>
                            <p>home</p>
                            <p>about</p>
                            <p>news</p>
                            <p>gallery</p>
                            <p>portrait photos</p>
                            <p>contact</p>
                        </div>
                    </div>
                </div>

                <div className='flex flex-row justify-between items-center px-5'>
                    <div className='block lg:hidden'>
                        <img className='w-[50px]' src={logo} alt="brand logo" />
                    </div>
                    <div onClick={() => setIsOpen(!isOpen)} className='w-7 h-8 cursor-pointer text-white block lg:hidden'>
                        {isOpen ? <FaXmark /> : <TfiAlignJustify />}
                    </div>
                </div>
                <div className={`block lg:hidden sm:flex text-[13px] w-full items-center absolute space-y-6 capitalize pl-9 mt-3 py-4 z-50 left-0 transition-all bg-white duration-500 ease-in ${isOpen ? 'top-13' : 'top-[-490px]'}`}>
                    <ul>
                        <li onClick={() => setIsOpen(false)}>
                            <Link to="/">home</Link>
                        </li>
                        <li onClick={() => setIsOpen(false)}>
                            <Link to="/about">about</Link>
                        </li>
                        <li onClick={() => setIsOpen(false)}>
                            <Link to="/products">news</Link>
                        </li>
                        <li onClick={() => setIsOpen(false)}>
                            <Link to="/shop">gallery</Link>
                        </li>
                        <li onClick={() => setIsOpen(false)}>
                            <Link to="/shop">portrait photos</Link>
                        </li>
                        <li onClick={() => setIsOpen(false)}>
                            <Link to="/contact-us">contact</Link>
                        </li>
                    </ul>
                </div>

            </div>
        </>
    )
}

export default NavBar
